import { computed, watch } from 'vue';

import { createSharedComposable, useStorage } from '@vueuse/core';

import { ShortcutModel } from '../models/ShortcutModel';
import { availableShortcuts } from '../defaults/availableShortcuts';

import { usePubSub } from '@/composables/atoms/usePubSub';

import { useKeysPressed } from '@/composables/atoms/useKeysPressed';

const useMessagingShortcuts = () => {
  const enableShortcuts = useStorage('enable-shortcuts', true);

  const pubSub = usePubSub<{ [Key in ShortcutModel['name']]?: () => void }>();

  const { currentShortcut } = useKeysPressed();

  const activeShortcut = computed((): ShortcutModel | void =>
    availableShortcuts.find((shortcut: ShortcutModel) => shortcut.code === currentShortcut.value)
  );

  watch(
    activeShortcut,
    (newShortcutActivated: ShortcutModel | void = null, oldShortcutActivated: ShortcutModel | void = null): void => {
      if (
        !enableShortcuts.value ||
        !newShortcutActivated ||
        (oldShortcutActivated && newShortcutActivated.code === oldShortcutActivated.code)
      ) {
        return;
      }

      pubSub.emit(newShortcutActivated.name);
    },
    { deep: true }
  );

  return {
    activeShortcut,
    onShortcut: pubSub.on,
    emitShortcut: pubSub.emit,
    offShortcut: pubSub.off,
  };
};

const useMessagingShortcutsService: typeof useMessagingShortcuts = createSharedComposable(useMessagingShortcuts);

export { useMessagingShortcutsService as useMessagingShortcuts };
