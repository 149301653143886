import gql from 'graphql-tag';
import { AccountFragmentLight } from '@/fragments';

const patientCardDetailsFragment = gql`
  fragment patientCardDetails on PatientType {
    id
    userId
    chatId
    firstName
    middleName
    lastName
    preferredName
    dateOfBirth
    dateOfBirthConfirmedAt
    displayGender
    displayName
    progressStage
    insuranceEligibility
    readableId
    shippingAddress {
      address
      city
      state
      zipcode
    }
    shippingAddressStr
    createdAt
    phone
    guardianFirstName
    guardianMiddleName
    guardianLastName
    guardians {
      id
      firstName
      lastName
    }
    identityConfirmedBy {
      ...accountLight
    }
    identityConfirmedAt
    preClinicalDataConfirmedAt
    user {
      id
      email
      createdAt
      intercomId
      intercomProfileUrl
      preferredPaymentOption
      testingPaymentMethod
      medhistoryCompletedAt
      unsubscribedSms
      unsubscribedEmail
    }
    physician {
      id
      firstName
      lastName
      email
      displayRole
      personalCalendlyLink
      personalZoomLink
      title
      npi
    }
    callCenterAgent {
      id
      firstName
      lastName
      email
      displayRole
    }
    idCards {
      id
      cardImageFront
      cardImageBack
    }
    weight
    height
  }
  ${AccountFragmentLight}
`;

export { patientCardDetailsFragment };
