import { MaybeRef, toValue } from '@vueuse/core';
import type { AccountType, ChatType, PatientType } from '@/types';
import gql from 'graphql-tag';
import { useApollo } from '@/composables/atoms/useApollo';
import { computed } from 'vue';
import { useRoot } from '@/composables/atoms/useRoot';
import { showError, showMessage } from '@/components/errors';
import type { MutationOptions } from 'apollo-client/core/watchQueryOptions';
import { formatInTimeZone } from 'date-fns-tz';
import { useStaffersStore } from '@/stores/staffers';

const WEBHOOK_MUTATION = gql`
  mutation CallN8NWebhook($webhookId: String!, $payload: JSONString!, $testMode: Boolean = false) {
    callN8nWebhook(webhookId: $webhookId, payload: $payload, testMode: $testMode) {
      status
    }
  }
`;

const PAYLOAD_QUERY = gql`
  query GetN8NWebhookPayload($id: UUID!) {
    account(id: $id) {
      id
      checkoutCompletedAt
      createdAt
      displayName
      displayNameShort
      medhistoryCompletedAt
      phone
      preclinicalCompletedAt
      preferredPaymentOption
      state
      stripeDelinquent
      testingPaymentMethod
      unsubscribedEmail
      unsubscribedSms
      patient {
        SLITStatus
        dxLaboratory
        id
        identityConfirmedAt
        userId
        latestSmsTimestamp
        personaConfirmedAt
        progressStage
        smsStatsStr
        stageSmsCount
        previousInsuranceVisitDate
        nextInsuranceVisitDate
        nextScheduledVisitDate
        latestPreclinicalReminderSentAt
        latestInsuranceConsultationNotificationIndex
        appointments {
          id
          kind
          startTime
          endTime
          modality
          status
          timezone
        }
        insuranceCards {
          id
          createdAt
        }
        externalLabResults {
          id
          createdAt
        }
        externalLabOrders {
          id
          updatedAt
          lab
        }
        physician {
          firstName
          id
          lastName
          npi
          personalCalendlyLink
          personalZoomLink
          title
        }
        tags {
          id
        }
      }
    }
    chat(id: $id) {
      latestClientMessage {
        createdAt
      }
    }
  }
`;

type N8NWebhookPayload = AccountType & {
  patient?: Partial<PatientType>;
  chat?: Partial<ChatType>;
  $flow: Record<any, any> & {
    path: string;
    eventExecutionId: 'EHR';
  };
  $event: Record<any, any> & {
    createdAt: string;
    stafferId: string;
    stafferEmail: string;
  };
};

export type N8NWebhooksOptions = {
  $event?: Record<any, any>;
  $flow?: Record<any, any>;
  inTestMode?: boolean;
};

export function useN8NWebhooks(userId: MaybeRef<PatientType['userId']>) {
  const root = useRoot();
  const apollo = useApollo();
  const staffersStore = useStaffersStore();

  const id = computed(() => toValue(userId));

  function throwError(message: string): void {
    throw new Error(message);
  }

  async function getPayload(webhookId: string): Promise<N8NWebhookPayload> {
    const response = await apollo.query<{ account: AccountType; chat: ChatType }>({
      fetchPolicy: 'network-only',
      query: PAYLOAD_QUERY,
      variables: { id: id.value },
    });

    if (response?.errors?.length) {
      throwError(response.errors.join('. '));
    }

    return {
      ...response.data.account,
      chat: response.data.chat,
      $flow: { path: webhookId, eventExecutionId: 'EHR' },
      $event: {
        source: 'ehr',
        createdAt: formatInTimeZone(new Date(), 'UTC', "yyyy-MM-dd'T'HH:mm:ss.sss'Z'", { timeZone: 'UTC' }),
        stafferId: staffersStore?.currentStaffer?.id,
        stafferEmail: staffersStore.currentStaffer.email,
      },
    };
  }

  async function createVariables(webhookId: string, options?: MaybeRef<N8NWebhooksOptions>) {
    const toValueOptions = toValue(options ?? {});
    const testMode = !!toValueOptions.inTestMode;
    const payload = await getPayload(webhookId);

    payload.$flow = { ...payload.$flow, ...(toValueOptions?.$flow ?? {}) };
    payload.$event = { ...payload.$event, ...(toValueOptions?.$event ?? {}) };

    return {
      payload: JSON.stringify(payload, null, 2),
      testMode,
      webhookId,
    };
  }

  async function buildMutationOptions(
    webhookId: string,
    options?: MaybeRef<N8NWebhooksOptions>
  ): Promise<MutationOptions<{ account: AccountType; chat: ChatType }>> {
    const variables = await createVariables(webhookId, options);

    return {
      mutation: WEBHOOK_MUTATION,
      variables,
      fetchPolicy: 'no-cache',
    };
  }

  async function callWebhook(webhookId: string, options?: MaybeRef<N8NWebhooksOptions>): Promise<boolean> {
    let success: boolean = true;

    try {
      const mutationOptions = await buildMutationOptions(webhookId, options);
      const response = await apollo.mutate<{ account: AccountType; chat: ChatType }>(mutationOptions);

      if (response?.errors?.length) {
        success = false;
        throwError(response.errors.join('. '));
      }

      showMessage(root, 'Success! N8N workflow activated!');
    } catch (error: any) {
      success = false;
      showError(root, `Oops! Something went wrong. ${error.message}`);
    }

    return success;
  }

  async function postVisitSurvey(options?: MaybeRef<N8NWebhooksOptions>): Promise<boolean> {
    return callWebhook('post-visit-survey', options);
  }

  async function art(options?: MaybeRef<N8NWebhooksOptions>): Promise<boolean> {
    return callWebhook('ops-art', options);
  }

  async function testWebhook(options?: MaybeRef<N8NWebhooksOptions>): Promise<boolean> {
    return callWebhook('test-webhook', options);
  }

  return {
    loading: apollo.loading,
    art,
    postVisitSurvey,
    testWebhook,
  };
}
