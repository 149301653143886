import { render, staticRenderFns } from "./PatientCard.vue?vue&type=template&id=ad5d04a6&scoped=true&"
import script from "./PatientCard.vue?vue&type=script&lang=ts&"
export * from "./PatientCard.vue?vue&type=script&lang=ts&"
import style0 from "./PatientCard.vue?vue&type=style&index=0&id=ad5d04a6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad5d04a6",
  null
  
)

export default component.exports